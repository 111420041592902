import React, { Component } from "react"
import { Grid } from "@material-ui/core"
import Layout from "components/Layout"
import "./styles/customer-review.css"
import SwipeLightBox from "../../components/swipeLightBox"
import SEO from "../../components/seo"
class CustomerReview extends Component {
  getImages = () => {
    const images = []

    for (let i = 0; i < 15; i++) {
      images.push(
        `https://just-rent.s3-ap-southeast-1.amazonaws.com/customer-review/cr${i +
        1}.png`
      )
    }

    return images
  }

  render() {
    const imgShare = `${process.env.AWS_S3_URL}/assets/share-social-new.jpg`
    const images = this.getImages()
    return (
      <Layout page="customer-review">
        <SEO
          title="BEST HAVEN POOLVILLA"
          description="แหล่งรวมบ้านพักพูลวิลล่าพร้อมสระว่ายน้ำส่วนตัว
    และบ้านพักตากอากาศที่มากที่สุดและราคาดีที่สุดในโซนเขาใหญ่
    และบริเวณใกล้เคียงโดยสามารถจองผ่านทางเราโดยตรงได้ทันที"
          image={imgShare}
        />
        <Grid container>
          <Grid item align="center" xs={12}>
            <span className="cr-header">รีวิวจริงจากลูกค้า</span>
            <div className="cr-header-line"></div>

            <SwipeLightBox images={images} />
            <br />
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

export default CustomerReview
